import React, { useEffect } from "react"
import iconCheck from "../Auth/img/icon-check.png"
import { useTranslation } from "react-i18next"

const VotingSuccess = ({ text, title }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "Nostalgie-stemactie",
        action: `Stem completed - ${title}`,
      })
    }
  }, [])

  return (
    <div className="voting-success">
      <div className="form__intro">
        <div className="form__intro--icon">
          <img src={iconCheck} alt="" />
        </div>
        <h3 className="form__intro--title">{t("votingModal.title")}</h3>
        <p className="form__intro--body">{text}</p>
      </div>
    </div>
  )
}

export default VotingSuccess

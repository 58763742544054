import React from "react"
import { useField } from "formik"
import "./TextAreaInput.scss"

function TextAreaInput({ label, children, className, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div
      className={`field${
        meta.touched && meta.error ? " error" : ""
      } ${className}`}
    >
      {!!label && <span className="field--label">{label}</span>}
      <textarea
        className="field--text-area-input"
        name={field.name}
        value={field.value ?? ""}
        onChange={field.onChange}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="field--error">{meta.error}</div>
      ) : null}
      {children}
    </div>
  )
}

TextAreaInput.defaultProps = {
  className: "",
}

export default TextAreaInput

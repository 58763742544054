import React, { useEffect } from "react"
import { Router } from "@reach/router"
import VoteDetail from "../components/Votes/Detail"
import { useVotes } from "../hooks/useVotes"
const Vote = () => {
  const { clearVotingVotes, clearLastVoting } = useVotes()
  useEffect(() => {
    clearVotingVotes()
    clearLastVoting()
  }, [])
  return (
    <Router>
      <VoteDetail path="stem/:slug" />
    </Router>
  )
}

export default Vote

import React, { useState } from "react"
import "./SongList.scss"
import { useTranslation } from "react-i18next"
import { useGuestApi } from "../../hooks/useApi"
import TextAreaInput from "../Forms/TextAreaInput"
import TextInput from "../Forms/TextInput"
import BtnForms from "../Buttons/BtnForms"
import { Formik } from "formik"
import { useVotes } from "../../hooks/useVotes"
import { useOAuthTokens } from "../../hooks/useOAuthTokens"
import global from "../../global/global"
import Icon from "../Icon/Icon"

function MyVotes({ voteList, title }) {
  const { votes, setVotingVotes, clearVotingVotes, setVotingSuggestions, setVotingQuestions, clearVotingQuestions } =
    useVotes()
  const { createVote, getMeByAccessToken, createQuestions } = useGuestApi()
  const [submitting, setSubmitting] = useState(false)
  const { accessToken } = useOAuthTokens()
  const { t } = useTranslation()

  function removeVote(voteId) {
    if (voteId) {
      let existingVotes = [...votes]
      let voteIndex = existingVotes.findIndex(vote => vote.id === voteId)
      if (voteId >= 0) {
        existingVotes.splice(voteIndex, 1)
        setVotingVotes(existingVotes)
      }
    }
  }

  const getQuestionFromFormData = (formData, voterId) => {
    return Object.keys(formData).map(key => {
      if(key.includes("question")) {
        return {
          voter_id: voterId,
          question_id: key.replace(/\D/g, ""),
          answer: formData[key]
        }
      }
    }).filter((e) => e);
  }

  const getBlankQuestionFromFormData = (formData) => {
    return Object.keys(formData).map(key => {
      if(key.includes("question")) {
        return {
          question_id: key.replace(/\D/g, ""),
          answer: formData[key]
        }
      }
    }).filter((e) => e);
  }

  function submitVote(values) {
    setVotingSuggestions(values.suggestions)
    setVotingQuestions(getBlankQuestionFromFormData(values))
    if (accessToken) {
      getMeByAccessToken(accessToken).then(response => {
        if (response && accessToken) {
          const questions = getQuestionFromFormData(values, response.id);
          let voteIds = []
          votes.map(vote => {
            voteIds.push(vote.id)
          })
          const obj = {
            voter_id: response.id,
            list_item_ids: voteIds,
            comment: values.suggestions,
          }
          createVote(obj).then(response => {
            setSubmitting(false)
            clearVotingVotes()
            window.location.reload()
          })
          createQuestions(questions).then(() => {
            clearVotingQuestions()
          });
        } else {
          setSubmitting(false)
          global.modal.setShowAuthModal(true)
        }
      })
    } else {
      setSubmitting(false)
      global.modal.setShowAuthModal(true)
    }
  }

  return (
    <div className="songlist">
      <div className="songlist__content">
        <div className="content--body">
          {votes.map((vote, i) => {
            return (
              <div className="content--row" key={i}>
                <div className="song--info">
                  <div className="title">{vote.title}</div>
                  <div className="artist">
                    <strong>{vote.artist}</strong>
                  </div>
                </div>
                <div className="vote">
                  <button
                    onClick={() => removeVote(vote.id)}
                    className="vote--remove"
                  >
                    <Icon name="close" />
                  </button>
                </div>
              </div>
            )
          })}
        </div>
        <div className="profile">
          <Formik
            initialValues={{ suggestions: "" }}
            onSubmit={values => {
              setSubmitting(true)
              submitVote(values)
              if (window.dataLayer) {
                window.dataLayer.push({
                  event: "Nostalgie-stemactie",
                  action: `Bevestig stem - ${title}`,
                })
              }
            }}
          >
            {({ handleSubmit }) => (
              <form className="form" onSubmit={handleSubmit}>
                {/* <h4 className="form__intro--subtitle">
                  Waarom raken deze songs een extra snaar bij jou? <br/> Aan welk topmoment in je leven zijn ze gelinkt?
                </h4> */}
                <div className="form--fields">
                  {voteList?.questions ? voteList.questions.map((question, index) => {
                    return (
                      <TextAreaInput
                        key={index}
                        name={`question-${question.id}`}
                        label={question.question}
                      />
                    )
                  }) : null}
                </div>
                <h4 className="form__intro--subtitle">
                  Jouw song niet gevonden?
                </h4>
                <div className="form--fields">
                  <TextAreaInput
                    name="suggestions"
                    placeholder={"Bezorg ons hier je suggestie!"}
                  />
                </div>
                <div className="form--btns">
                  <BtnForms
                    large
                    primary
                    disabled={
                      parseInt(voteList.max_votes) < 3
                        ? votes.length !== parseInt(voteList.max_votes)
                        : votes.length < 3
                    }
                    loading={submitting}
                  >
                    {(
                      parseInt(voteList.max_votes) < 3
                        ? votes.length === parseInt(voteList.max_votes)
                        : votes.length >= 3
                    )
                      ? "Bevestig je stem"
                      : t("myVotes.count", {
                          count: voteList.max_votes - votes.length,
                        })}
                  </BtnForms>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default MyVotes

import React, { useEffect, useState } from "react"
import MainContent, {
  MainContentLoading,
} from "src/components/Layout/MainContent"
import Title from "src/components/Text/Title"
import Voting from "src/components/Voting/Voting"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import { formatPageInformation } from "../../helpers/Meta"
import {
  getSingleImage,
  getTitle,
  getVoteSubmittedCopy,
  getVotingModuleListId,
} from "../../helpers/Publications"
import { useTranslation } from "react-i18next"
import { returnLayoutSection } from "../../helpers/Layout"
import "./Detail.scss"
import { getImageType } from "../../helpers/Prepr"
import { useLocation } from "@reach/router"
import { useVotes } from "../../hooks/useVotes"
import { useOAuthTokens } from "../../hooks/useOAuthTokens"
import VotingSuccess from "../Voting/VotingSuccess"

function VoteDetail({ slug }) {
  const {
    getVotePublicationBySlug,
    setPageInformation,
    getVoteListById,
    checkVotingParticipation,
    getMeByAccessToken,
  } = useGuestApi()
  const { setLastVoting } = useVotes()
  const [voteItem, setVoteItem] = useState(null)
  const [loading, setLoading] = useState(true)
  const [voteList, setVoteList] = useState(null)
  const [canParticipate, setCanParticipate] = useState(true)
  const { t } = useTranslation()
  const { accessToken } = useOAuthTokens()
  const location = useLocation()
  useEffect(
    function fetchPublications() {
      getVotePublicationBySlug(slug).then(response => {
        setLoading(false)
        setPageInformation(formatPageInformation(response, "article"))
        setVoteItem(response)
      })
    },
    [slug]
  )

  useEffect(() => {
    if (location) {
      setLastVoting(location.pathname)
    }
  }, [location])

  useEffect(() => {
    if (voteItem) {
      getVoteListById(
        getVotingModuleListId(voteItem.votingmodule_list_id)
      ).then(responseVote => {
        setVoteList(responseVote)
        if (accessToken) {
          getMeByAccessToken(accessToken).then(response => {
            checkVotingParticipation(response.id, responseVote.id).then(
              responseData => {
                setCanParticipate(!responseData)
              }
            )
          })
        }
      })
    }
  }, [voteItem])

  return (
    <div className="vote">
      {loading && <MainContentLoading />}
      {voteItem && (
        <MainContent
          image={voteItem.image}
          label={t("voteDetail.label")}
          color="#f93822"
        >
          <Title>{getTitle(voteItem.title)}</Title>
          {voteItem.layout
            ? Array.isArray(voteItem.layout.items)
              ? voteItem.layout.items.map((item, i) => {
                  return returnLayoutSection(item, i)
                })
              : null
            : null}
          {canParticipate ? (
            <>
              {voteList && (
                <Voting title={getTitle(voteItem.title)} voteList={voteList} />
              )}
            </>
          ) : (
            <VotingSuccess
              text={getVoteSubmittedCopy(voteItem.vote_submitted_copy)}
              title={getTitle(voteItem.title)}
            />
          )}
        </MainContent>
      )}
    </div>
  )
}

export default withGuestLayout(VoteDetail, {
  page: "Stemmen",
})

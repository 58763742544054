import React, { useState, useEffect } from "react"
import SongList from "./SongList"
import MyVotes from "./MyVotes"
import "./Voting.scss"
import { useVotes } from "../../hooks/useVotes"

function Voting({ voteList, title }) {
  const { votes } = useVotes()
  const [activeTab, setActiveTab] = useState("songs")
  const [voteCompleted, setVoteCompleted] = useState(false)

  useEffect(() => {
    if (votes.length === parseInt(voteList.max_votes) && !voteCompleted) {
      if (activeTab !== "votes") {
        setVoteCompleted(true)
        setActiveTab("votes")
      }
    }
  }, [votes, voteList, voteCompleted])

  return (
    <div className="voting">
      <div className="voting__tabs">
        <button
          onClick={() => setActiveTab("songs")}
          className={`voting__tabs--item ${
            activeTab === "songs" ? "active" : ""
          }`}
        >
          Songs
        </button>
        <button
          onClick={() => setActiveTab("votes")}
          className={`voting__tabs--item ${
            activeTab === "votes" ? "active" : ""
          }`}
        >
          Mijn stemmen {votes.length > 0 && `(${votes.length})`}
        </button>
      </div>
      {activeTab === "songs" && <SongList voteList={voteList} title={title} />}
      {activeTab === "votes" && <MyVotes voteList={voteList} title={title} />}
    </div>
  )
}

export default Voting

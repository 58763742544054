import React from "react"
import Icon from "../Icon/Icon"

function SongListVotes({ voteLimit, votes, onClickRemove }) {
  const voteList = () => {
    const rows = []
    for (let i = 0; i < voteLimit; i++) {
      rows.push(
        <div
          className={`${votes[i] ? "active" : ""} vote--row`}
          key={`vote-row-${i}`}
        >
          <div className="count">{i + 1}</div>
          <div className={`song--info`}>
            <div className="title">
              {votes.length
                ? votes[i]
                  ? votes[i].title
                  : "Breng je stem uit"
                : "Breng je stem uit"}
            </div>
            <div className="artist">
              <strong>
                {votes.length ? (votes[i] ? votes[i].artist : "") : ""}
              </strong>
            </div>
          </div>
          {votes.length ? (
            votes[i] ? (
              <div className="vote">
                <button
                  onClick={() =>
                    onClickRemove(votes.length ? votes[i].id : null)
                  }
                  className="vote--remove"
                >
                  <Icon name="close" />
                </button>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      )
    }
    return rows
  }

  return (
    <div className="songlist__votes">
      <h3 className="songlist__votes--title">Mijn top {voteLimit}</h3>
      {voteList()}
    </div>
  )
}

export default SongListVotes

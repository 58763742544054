import React, { useState, useEffect } from "react"
import "./SongList.scss"
import { useTranslation } from "react-i18next"
import { useGuestApi } from "../../hooks/useApi"
import SearchField from "../Forms/SearchField"
import { Formik } from "formik"
import SongListVotes from "./SongListVotes"
import { useVotes } from "../../hooks/useVotes"
import ReactPaginate from "react-paginate"
import { DateTime } from "luxon"
import Icon from "../Icon/Icon"

const initialPagination = {
  page: 1,
  limit: 25,
  total_pages: 0,
}

function VoteBtn({ active, onClick }) {
  return (
    <button className={`vote--btn ${active ? "active" : ""}`} onClick={onClick}>
      {active ? <Icon name="check" /> : <span>Stem</span>}
    </button>
  )
}

function SongList({ voteList, title }) {
  const { t } = useTranslation()
  const { getListTracks, searchListTracks } = useGuestApi()
  const [tracks, setTracks] = useState([])
  const [pagination] = useState(initialPagination)
  const { votes, setVotingVotes } = useVotes()
  const [query, setQuery] = useState("")
  useEffect(fetchPublications, [voteList, query])

  useEffect(() => {
    if (query.length >= 3) {
      searchListTracks({
        query: query,
        list_id: voteList.id,
        timestamp: DateTime.local().toISO(),
      }).then(response => {
        setTracks(response.filter(track => track.list_id === voteList.id))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  function fetchPublications() {
    if (query.length === 0) {
      getListTracks(voteList.id, pagination).then(response => {
        pagination.page = response.page
        pagination.total_pages = response.total_pages
        setTracks(response.items)
      })
    }
  }

  function changePage(data) {
    pagination.page = data.selected + 1
    fetchPublications()
  }

  function voteTrack(track) {
    let existingVotes = [...votes]
    let voteIndex = existingVotes.findIndex(vote => vote.id === track.id)
    if (voteIndex >= 0) {
      existingVotes.splice(voteIndex, 1)
    } else {
      if (votes.length < parseInt(voteList.max_votes)) {
        existingVotes.push(track)
      }
    }
    setVotingVotes(existingVotes)
  }

  function removeVote(voteId) {
    if (voteId) {
      let existingVotes = [...votes]
      let voteIndex = existingVotes.findIndex(vote => vote.id === voteId)
      if (voteId >= 0) {
        existingVotes.splice(voteIndex, 1)
        setVotingVotes(existingVotes)
      }
    }
  }

  return (
    <div className="songlist__wrap">
      <div className="songlist">
        <div className="songlist__search">
          <Formik initialValues={{ search: "" }}>
            {({ setFieldValue }) => (
              <form className="form" onSubmit={e => e.preventDefault()}>
                <SearchField
                  autoFocus={false}
                  name="search"
                  placeholder={"Zoek songs"}
                  onChange={e => {
                    setFieldValue("search", e.target.value)
                    setQuery(e.target.value)
                    if (window.dataLayer) {
                      window.dataLayer.push({
                        event: "Nostalgie-stemactie",
                        action: `Zoek song - ${title}`,
                      })
                    }
                  }}
                />
              </form>
            )}
          </Formik>
        </div>
        <div className="songlist__content">
          <div className="content--body">
            {tracks.map((track, i) => {
              const active =
                [...votes].findIndex(vote => vote.id === track.id) >= 0
              return (
                <div className="content--row" key={i}>
                  <div className="song--info">
                    <div className="title">{track.title}</div>
                    <div className="artist">
                      <strong>{track.artist}</strong>
                    </div>
                  </div>
                  {votes.length !== parseInt(voteList.max_votes) && (
                    <div className="vote">
                      <VoteBtn
                        active={active}
                        onClick={() => {
                          voteTrack(track)
                          if (window.dataLayer) {
                            window.dataLayer.push({
                              event: "Nostalgie-stemactie",
                              action: `Stem op song - ${title}`,
                            })
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              )
            })}
            {tracks.length === 0 && (
              <div className="search--noresults">
                <p>
                  We vonden niets dat overeen komt met je huidige zoekopdracht
                </p>
              </div>
            )}
          </div>
          {query.length === 0 && (
            <div className="content--pagination">
              <ReactPaginate
                previousLabel={""}
                nextLabel={""}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pagination.total_pages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={3}
                onPageChange={changePage}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      </div>
      <SongListVotes
        voteLimit={voteList.max_votes}
        votes={votes}
        onClickRemove={voteId => removeVote(voteId)}
      />
    </div>
  )
}

export default SongList
